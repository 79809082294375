import ReactDOM from 'react-dom';
import 'moment/dist/locale/zh-cn';

import App from './App.tsx';

import './utils/i18n';

// 少量公共样式
import 'tdesign-react/es/style/index.css';
// import 'tdesign-react/dist/tdesign.min.css';
// import './theme.css';
import '@tencent/tpm-audio-video-base/dist/TpmAudioVideoBase.min.css';
// import '@tencent/tea-component/dist/tea.css';
import '@tencent/tpm-trtc-console/trtc-tea/trtc-tea.css';
// 导入 富文本编辑器样式
import 'quill/dist/quill.snow.css';
import { DialogContainer } from './dialog/DialogContainer.tsx';
import { MainContainer } from './MainContainer.tsx';
console.log(import.meta.env);

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  // <React.StrictMode>
  <MainContainer>
    <App />
  </MainContainer>,
  // </React.StrictMode>,
  document.getElementById('root')!,
);
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  // <React.StrictMode>
  <MainContainer>
    <DialogContainer />
  </MainContainer>,
  // </React.StrictMode>,
  document.getElementById('dialog-root')!,
);
